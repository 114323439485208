<template>
  <div>
    <b-modal id="changeStatus" size="lg" title="Change Status" style="direction: rtl" centered hide-footer hide-header-close>
      <b-row class="d-flex flex-wrap" >
        <b-col v-for="(status, key) in allStatus" :key="key" class="col-3 text-black text-center mb-3 cursor-pointer" >
           <span
               :class="[{ 'shadow bg-primary text-white' : isSelected(status.value)},'d-inline-block w-100 p-1 custom-rounded  primary-color ']" @click="selectedStatusId = status.value">
            <i  :class="[{ 'las la-check-square' : isSelected(status.value)}]"></i> {{status.text}}
          </span>
          <span  class="d-block w-100 h-10 bg-white"></span>
          <span  :class="`status--${status.value} d-block w-100 h-10`">
          </span>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="saveEditStatus">Save</b-button>
      </div>
    </b-modal>
    <dashboard-page-title :showAddBtn="false">الطلبات</dashboard-page-title>

    <main-table :fields="fields" list_url="merchant/orders" :reloadData="reloadData" paginationName="طلبات"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import orderService from '../services/order'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      reloadData: true,
      // All Status
      allStatus: [
        {
          value: 'pending',
          text: 'جارى الطلب'
        },
        {
          value: 'on_delivery',
          text: 'جارى الشحن'
        },
        {
          value: 'refused',
          text: 'تم الرفض'
        },
        {
          value: 'success',
          text: 'تم الاستلام'
        }
      ],
      saveButton: false,
      selectedStatusId: '',
      orderId: null,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم العميل', key: 'order_address.name', class: 'text-right' },
        { label: 'المنتج', key: 'order_products', array_keys: ['product_name'], class: 'text-right', type: 'array' },
        { label: 'العنوان', key: 'order_address.address', class: 'text-right' },
        { label: 'رقم الهاتف', key: 'order_address.phone', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'المبلغ الاجمالى', key: 'total_amount', class: 'text-right' },
        { label: 'تاريخ الطلب', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'تغيير الحالة',
              icon: 'las la-sync',
              color: 'primary',
              actionName: 'showPopupChangeStatusOrder',
              actionParams: ['id']
            }
          ]
        }
      ]
    }
  },
  methods: {
    saveEditStatus () {
      this.saveButton = true
      orderService.changeOrderStatus(this.orderId, this.selectedStatusId).then(res => {
        core.showSnackbar('success', 'تم تغير حالة الطلب بنجاح')
        this.saveButton = false
        this.$bvModal.hide('changeStatus')
      })
    },
    isSelected (value) {
      return value === this.selectedStatusId
    },
    showPopupChangeStatusOrder (data) {
      this.orderId = data.id
      this.$bvModal.show('changeStatus')
    }
  },
  created () {
    this.$root.$on('showPopupChangeStatusOrder', this.showPopupChangeStatusOrder)
  }
}
</script>
